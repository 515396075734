<template lang="pug">
TableV2(
  :headers="headers"
  :items="items.results"
  :actions="actions"
  :isLoading="isLoading"
  :pageCount="items.count"
  :currentPage="items.current"
  :querySearch="getCrewingManagerStatements")
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { checkAccess } from '@/mixins/permissions'
export default {
  name: 'CrewingManagerStatements',
  data () {
    return {
      actions: [
        {
          id: 1,
          tooltip: 'openSailor',
          action: item => this.$router.push({ name: 'passports-sailors', params: { id: item.sailor_key.id } }),
          color: 'blue',
          name: 'mdi-account-outline',
          isView: item => item.sailor_key
        },
        {
          id: 2,
          tooltip: 'tooltip.info',
          to: item => ({ name: 'crewing-manager-statement-from-sailor-info', params: { documentID: item.id } }),
          color: 'blue',
          name: 'mdi-arrow-right',
          isView: item => item.id
        }
      ]
    }
  },
  computed: {
    ...mapState({
      items: state => state.statement.crewingManagerStatements,
      isLoading: state => state.statement.isLoading
    }),
    headers () {
      const headers = [
        { value: 'created_at', text: this.$t('tableHeaders.created_at') },
        { value: 'sailor_full_name_ukr', text: this.$t('tableHeaders.sailor_full_name_ukr'), sortable: false },
        { value: 'manager.userprofile.city', text: this.$t('city'), sortable: false },
        { value: 'status_document', text: this.$t('tableHeaders.status_statement') },
        { value: 'event', class: 'text-center', text: this.$t('actions'), sortable: false, align: 'center' }
      ]
      if (checkAccess('crewingManagerStatements', 'showCrewing')) {
        headers.splice(1, 0, { value: 'manager', text: this.$t('crewingManagerID'), align: 'center' })
      }
      return headers
    }
  },
  beforeCreate () {
    this.$store.commit('setActivePage', { name: 'crewingManagerStatements', access: checkAccess('menuItem-crewingManagerStatementFromSailor') })
  },
  methods: {
    ...mapActions(['getCrewingManagerStatements'])
  }
}
</script>
